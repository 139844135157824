
import { enumToMap } from "@/models/schoolfinder.enums";
import { Component, Vue, Watch, Mixins } from "vue-property-decorator";
import {
  School,
  SchoolCompareRequest,
  SchoolCompareResult,
  SchoolSearchCriteria,
} from "../models/interface";
import { CompareFav } from "@/mixins/compare-fav.mixin";
import ConfirmationModal from "@/components/ConfirmationModal.vue";
import CaroselWrapperComponent from "@/components/CaroselWrapperComponent.vue";
import { EventBus } from "@/event-bus";
import SchoolMixin from "@/mixins/school.mixin";
import CommonMixin from "@/mixins/common.mixin";
import { metaData } from "@/assets/js/common-meta-info";
import { setMetaInfo } from "@/assets/js/meta-info-config";
import CommonAsyncMixin from "@/mixins/common-async.mixin";
import { ProfileType, profileTypeArray } from "@/models/schoolfinder.enums";

const getObjectFromSessionStorage = (key: string) => {
  const nap = sessionStorage.getItem(key);
  if (nap != null && nap != "undefined" && Object.keys(nap).length > 0) {
    return JSON.parse(nap);
  }
  return {};
};

const isNotEmpty = (obj: any) => {
  return Object.keys(obj).length !== 0;
};
@Component({
  components: {
    ConfirmationModal,
    CaroselWrapperComponent,
  },
})
export default class CompareSchools extends Mixins(
  CompareFav,
  SchoolMixin,
  CommonMixin
) {
  public schoolCompareParams = {} as SchoolCompareRequest;
  public schoolSearchCriteria = {} as SchoolSearchCriteria;
  public compareResults: SchoolCompareResult[] = [];
  public tempCompareResults: SchoolCompareResult[] = [];
  private compareList: School[] = [];
  //private school = {} as School;
  private removeSchoolFromComparison = {} as SchoolCompareResult;
  givenTitle = "";
  givenDescription = "";
  public latitudeForCompare = 0 as number;
  public longitudeForCompare = 0 as number;
  public viewType = "";
  public colWidthForCompareScreen = "col-lg-12";
  public colWidthForSections = "col-lg-4";
  public schoolFinderText = "";
  public displayToggelButtons = false;

  ProfileType = ProfileType;
  profileTypeArray = profileTypeArray;

  get getCompareList() {
    return this.compareList ? this.compareList : [];
  }

  mounted() {
    setMetaInfo({ title: "Compare School" });
    this.compareList = getObjectFromSessionStorage("compareList");
    let schooldIds = "";
    if (this.compareList.length > 0) schooldIds = this.compareList[0].entityId;

    if (this.compareList.length > 1 && this.compareList[1])
      schooldIds = schooldIds + "," + this.compareList[1].entityId;
    if (this.compareList.length > 2 && this.compareList[2])
      schooldIds = schooldIds + "," + this.compareList[2].entityId;
    // Default Adress 1201 North Third Street Baton Rouge, LA 70802-5243
    //this.latitudeForCompare = 30.404355;
    //this.longitudeForCompare = -91.060052;

    this.schoolSearchCriteria =
      this.$store.getters["app/getschoolSearchCriteria"];
    if (this.schoolSearchCriteria) {
      if (
        this.schoolSearchCriteria.latitude &&
        this.schoolSearchCriteria.longitude
      ) {
        this.latitudeForCompare = this.schoolSearchCriteria.latitude;
        this.longitudeForCompare = this.schoolSearchCriteria.longitude;
      }
    }
    console.log(schooldIds);
    this.schoolCompareParams = {
      //uniqueIds: "304002,68002,KPH001",
      //uniqueIds : "63005,EE-50841,K2H001",
      //uniqueIds  : "304002,68002,KPH001",
      uniqueIds: schooldIds,
      //schoolYear: this.schoolYear.currentYear,
      //schoolYear : 2019,
      latitude: this.latitudeForCompare,
      longitude: this.longitudeForCompare,
    };

    this.getCompareResults();
  }
  getSchoolType(schoolTypes: string) {
    const schoolTypeList = schoolTypes.split(",");
  }

  earlyChildhood(schoolProfile: string) {
    return (
      schoolProfile ===
      this.profileTypeArray[this.ProfileType.EarlyChildhood].name
    );
  }

  navigateToUrl(entityId = "") {
    //const url = "/schools/" + entityId + "/about-our-school";
    //this.$router.push(url);

    if (entityId == "") return;

    const selectedSchool = this.compareResults.find(
      (e: any) => e.uniqueId == entityId
    );

    if (selectedSchool != null) {
      let url;
      if (this.earlyChildhood(selectedSchool.profileType))
        url =
          "/schools/" +
          entityId +
          "/ec-school-about/" +
          selectedSchool.schoolName;
      else
        url =
          "/schools/" +
          entityId +
          "/about-our-school/" +
          selectedSchool.schoolName;

      console.log(url);
      this.$router.push(url);
    }
  }
  removeFromComparison(schoolCompareResult: SchoolCompareResult) {
    this.removeSchoolFromComparison = schoolCompareResult;
    this.givenTitle = "Remove from Comparison";
    this.givenDescription =
      "Are you sure you want to remove " +
      schoolCompareResult.schoolName +
      " from the Comparison List?";
    this.$bvModal.show("confirmationModal");

    //this.compareResults.splice(this.compareResults.indexOf(schoolCompareResult), 1);
  }
  async getCompareResults() {
    const result = await this.$store.dispatch(
      "app/getSchoolComparisonResults",
      this.schoolCompareParams
    );

    this.compareResults = await this.$store.state.app.schoolComparisonResults;
    this.colWidthForCompareScreen = "col-lg-" + this.compareResults.length * 4;
    this.colWidthForSections = "col-lg-" + 12 / this.compareResults.length;

    this.setDisplayView();
  }

  setDisplayView() {
    this.tempCompareResults = this.compareResults;

    if (this.compareResults.length == 0) {
      this.viewType = "";
      this.displayToggelButtons = false;
      return;
    }

    if (
      this.tempCompareResults.filter((item) => item.profileType === "EE")
        .length == this.compareResults.length
    ) {
      this.viewType = "earlyChildhoodView";
      this.displayToggelButtons = false;
      return;
    } else {
      this.displayToggelButtons = true;
    }

    this.tempCompareResults = this.compareResults;

    if (
      this.tempCompareResults.filter((item) => item.profileType === "K12")
        .length == this.compareResults.length
    ) {
      this.displayToggelButtons = false;
      this.viewType = "k12SchoolView";
      return;
    } else {
      this.displayToggelButtons = true;
    }

    this.viewType = "earlyChildhoodView";
  }

  navigateToSearchScreen() {
    this.schoolSearchCriteria =
      this.$store.getters["app/getschoolSearchCriteria"];
    if (this.schoolSearchCriteria) {
      if (
        this.schoolSearchCriteria.latitude &&
        this.schoolSearchCriteria.longitude
      ) {
        return this.$router.push("/searchresults");
      }
    }
    return this.$router.push("/");
  }

  ModalClickEvent(data: boolean) {
    if (data && this.removeSchoolFromComparison) {
      this.compareResults.splice(
        this.compareResults.indexOf(this.removeSchoolFromComparison),
        1
      );
      this.colWidthForCompareScreen =
        "col-lg-" + this.compareResults.length * 4;
      this.colWidthForSections = "col-lg-" + 12 / this.compareResults.length;
      this.setDisplayView();
      this.removeFromCmprList(this.removeSchoolFromComparison);
      console.log("View Type" + this.viewType);
    }
  }

  removeFromCmprList(removeSchoolFromComparison: SchoolCompareResult) {
    const compareList: School[] = [];
    const existingCompareList = getObjectFromSessionStorage("compareList");
    if (isNotEmpty(existingCompareList)) {
      for (const i in existingCompareList) {
        if (
          existingCompareList[i].entityId != removeSchoolFromComparison.uniqueId
        ) {
          compareList.push(existingCompareList[i]);
        }
      }
    }
    sessionStorage.setItem("compareList", JSON.stringify(compareList));
    sessionStorage.setItem(
      "compareCount",
      this.compareResults.length.toString()
    );
    EventBus.$emit(
      "compare-list-modified",
      this.compareResults.length.toString()
    );
  }
  changeCompareView(compareView: string) {
    this.viewType = compareView;
  }
  getCategoryResults(
    schoolCompareResult: SchoolCompareResult,
    category: string
  ) {
    if (schoolCompareResult && schoolCompareResult.schoolCompareRecords) {
      const filteredRecords = schoolCompareResult.schoolCompareRecords.filter(
        (x) => x.sectionName.indexOf(category) > -1
      );
      return filteredRecords;
    } else return [];
  }
  private getRatingColorClass(rating: string) {
    if (rating) {
      return `bg-rating-${rating.toLowerCase()}`;
    }
  }
  // filter end
}
