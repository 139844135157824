
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class CaroselWrapperComponent extends Vue {
  @Prop({ default: () => [] }) givenArray!: any[];
  windowWidth = 1200;
  randNum = 1;

  // getters
  get getMobileView() {
    return this.$store.state.app.mobileView;
  }
  // methods
  mounted() {
    this.randNum = this.getRandomArbitrary(1, 100);
  }
  getRandomArbitrary(min: number, max: number) {
    return Math.ceil(Math.random() * (max - min) + min);
  }
}
