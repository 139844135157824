import { Component, Vue } from "vue-property-decorator";
import { School } from "@/models/interface";
import { EventBus } from "@/event-bus";



@Component
export class CompareFav extends Vue {
    public maxNumberOfComparePossible = 3;
    private favoritesCount = 0;
    private compareCount = 0;

    public getObjectFromSessionStorage = (key: string) => {
        const nap = sessionStorage.getItem(key);
        if (nap != null && nap != 'undefined' && Object.keys(nap).length > 0) {
            return JSON.parse(nap);
        }
        return {};
    }
    
    public isNotEmpty = (obj: any) => {
        return Object.keys(obj).length !== 0;
    }

    mounted() {
        this.favoritesCount = this.latestfavoritesCount();
        this.compareCount = this.latestCompareCount();
    }

    addToFavList(school: School, index: number) {
        const favList: School[] = [];
        const existingFavList = this.getObjectFromSessionStorage("favList")
        if (this.isNotEmpty(existingFavList)) {
            for (const i in existingFavList) {
                favList.push(existingFavList[i]);
            }
        }
        school.schoolIndex = index;
        school.isFavorite = true;
        favList.push(school);
        sessionStorage.setItem("favList", JSON.stringify(favList));

        this.favoritesCount = this.favoritesCount + 1;
        sessionStorage.setItem("favoritesCount", this.favoritesCount.toString());

        this.setResetExistingCompareAsFavToo(school);

        EventBus.$emit("fav-list-modified", this.favoritesCount);
        this.$bvToast.show("addToFav");
    }

    setResetExistingCompareAsFavToo(school: School) {
        const existingCompareList: School[] = this.getObjectFromSessionStorage("compareList")
        if (this.isNotEmpty(existingCompareList)) {
            const compareIdx = existingCompareList.findIndex(s => s.entityId == school.entityId);
            if (compareIdx > -1) {
                existingCompareList[compareIdx].isFavorite = school.isFavorite;
                sessionStorage.setItem("compareList", JSON.stringify(existingCompareList));
            }
        }
    }

    addToCompareList(school: School, index: number) {
        if (this.latestCompareCount() == this.maxNumberOfComparePossible) {
            this.$bvToast.show("maxCompareLimit");
        }
        else {
            const compareList: School[] = [];
            const existingCompareList = this.getObjectFromSessionStorage("compareList")
            if (this.isNotEmpty(existingCompareList)) {
                for (const i in existingCompareList) {
                    compareList.push(existingCompareList[i]);
                }
            }
            school.schoolIndex = index;
            school.isToCompare = true;
            compareList.push(school);
            sessionStorage.setItem("compareList", JSON.stringify(compareList));

            this.compareCount = this.compareCount + 1;
            sessionStorage.setItem("compareCount", this.compareCount.toString());

            this.setResetExistingFavAsCompareToo(school);

            EventBus.$emit("compare-list-modified", this.compareCount);
            this.$bvToast.show("addToCompare");
        }
    }

    setResetExistingFavAsCompareToo(school: School) {
        const existingFavList: School[] = this.getObjectFromSessionStorage("favList")
        if (this.isNotEmpty(existingFavList)) {
            const favIdx = existingFavList.findIndex(s => s.entityId == school.entityId);
            if (favIdx > -1) {
                existingFavList[favIdx].isToCompare = school.isToCompare;
                sessionStorage.setItem("favList", JSON.stringify(existingFavList));
            }
        }
    }

    removeFromFavList(school: School) {
        if (this.latestfavoritesCount() > 0) {
            school.isFavorite = false;
            const favList: School[] = [];
            const existingFavList = this.getObjectFromSessionStorage("favList")
            if (this.isNotEmpty(existingFavList)) {
                for (const i in existingFavList) {
                    if (existingFavList[i].entityId != school.entityId) {
                        favList.push(existingFavList[i]);
                    }
                }
            }
            sessionStorage.setItem("favList", JSON.stringify(favList));

            this.favoritesCount = this.favoritesCount - 1;
            sessionStorage.setItem("favoritesCount", this.favoritesCount.toString());

            this.setResetExistingCompareAsFavToo(school);
            
            EventBus.$emit("fav-list-modified", this.favoritesCount);
            this.$bvToast.hide("addToFav");
        }
    }  

    removeFromCompareList(school: School) {
        if (this.latestCompareCount() > 0) {
            school.isToCompare = false;
            const compareList: School[] = [];
            const existingCompareList = this.getObjectFromSessionStorage("compareList")
            if (this.isNotEmpty(existingCompareList)) {
                for (const i in existingCompareList) {
                    if (existingCompareList[i].entityId != school.entityId) {
                        compareList.push(existingCompareList[i]);
                    }
                }
            }
            sessionStorage.setItem("compareList", JSON.stringify(compareList));

            this.compareCount = this.compareCount - 1;
            sessionStorage.setItem("compareCount", this.compareCount.toString());

            this.setResetExistingFavAsCompareToo(school);
            EventBus.$emit("compare-list-modified", this.compareCount);
            this.$bvToast.hide("addToCompare");
        }
    }
   
    latestfavoritesCount(): number {
        const tmp = sessionStorage.getItem("favoritesCount");
        if (tmp == null) {
            return 0;
        }
        return +tmp;
    }

    latestCompareCount(): number {
        const tmp = sessionStorage.getItem("compareCount");
        if (tmp == null) {
            return 0;
        }
        return +tmp;
    }
}