
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "ConfirmationModal",
})
export default class ConfirmationModal extends Vue {
  @Prop({ default: "" }) givenTitle!: string;
  @Prop({ default: "" }) givenDescription!: string;
  
  yesClicked() {
    this.$emit("Clicked", true);
    this.$bvModal.hide("confirmationModal");
  }
  noClicked() {
    this.$emit("Clicked", false);
    this.$bvModal.hide("confirmationModal");
  }
  mounted() {
    this.$bvModal.hide("confirmationModal");
  }
}
